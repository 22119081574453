<template>
  <div
    :class="{ 'is-busy': isAccepting || isRejecting }"
    class="d-flex align-items-center small position-relative"
  >
    <div class="flex-grow-1 me-3">{{ clientName }}</div>

    <BootstrapDropdown :class="{ 'is-invisible': isAccepting || isRejecting }">
      <li
        @click="accept"
        class="dropdown-item small"
        role="button"
      >
        <fa-icon :icon="['fas', 'check']" class="me-1"></fa-icon>
        Accept
      </li>
      <li><hr class="dropdown-divider"></li>
      <li
        @click="showConfirmModal = true"
        class="dropdown-item small text-danger"
        role="button"
      >
        <fa-icon :icon="['fas', 'trash-alt']" class="me-1"></fa-icon>
        Remove
      </li>
    </BootstrapDropdown>

    <span
      v-if="isAccepting || isRejecting"
      class="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    ></span>

    <portal to="modal">
      <transition name="modal">
        <ConfirmModal
          @confirm="reject"
          @close="showConfirmModal = false"
          v-model="showConfirmModal"
          v-if="showConfirmModal"
        ></ConfirmModal>
      </transition>
    </portal>
  </div>
</template>

<script>
export default {
  name: 'ClientRequestListItem',
  components: {
    ConfirmModal: () => import('@/components/modal/ConfirmModal'),
    BootstrapDropdown: () => import('@/components/bootstrap/BootstrapDropdown'),
  },
  props: {
    request: {
      type: Object,
      required: true,
    },
  },
  computed: {
    clientName() {
      const { firstName, middleName, lastName } = this.request.athlete;
      return `${firstName} ${middleName || ''} ${lastName}`;
    },
  },
  methods: {
    async accept() {
      this.isAccepting = true;
      try {
        const { userId } = this.request.athlete;
        await this.$store.dispatch('client/acceptRequest', userId);
        await this.$store.dispatch('client/fetchClients');
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isAccepting = false;
      }
    },
    async reject() {
      this.showConfirmModal = false;
      this.isRejecting = true;
      try {
        const { userId } = this.request.athlete;
        await this.$store.dispatch('client/rejectRequest', userId);
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isRejecting = false;
      }
    },
  },
  data() {
    return {
      showConfirmModal: false,
      isAccepting: false,
      isRejecting: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.spinner-border {
  position: absolute;
  right: .25rem;
}
</style>
